import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

// Páginas
const Home = lazy(() => import('./pages/home'));
const SobreHospital = lazy(() => import('./pages/hospital'));
const Contato = lazy(() => import('./pages/contato'));
const Convenios = lazy(() => import('./pages/convenios'));

// Paginas - Especialidades
const Especialidades = lazy(() => import('./pages/especialidades/'));
const CorrecaoLaser = lazy(() => import('./pages/especialidades/correcaoLaser'));
const CirurgiaCatarata = lazy(() => import('./pages/especialidades/cirurgiaCatarata'));
const TratamentoCeratocone = lazy(() => import('./pages/especialidades/tratamentoCetatocone'));
const RetinaVitreo = lazy(() => import('./pages/especialidades/RetinaVitreo'));
const Glaucoma = lazy(() => import('./pages/especialidades/glaucoma'));
const PlasticaPelpebral = lazy(() => import('./pages/especialidades/plasticaPalpebral'));


class Routes extends Component {
   constructor(props) {
      super();

      this.onUpdateRoute = this.onUpdateRoute.bind();
   }

   onUpdateRoute(path, title, refleshPage = false) {
      title = (title === '' ? 'Hospital de Olhos de Presidente Prudente - Oftalmo Care' : title + ' - Oftalmo Care');
      document.title = title;

      if (refleshPage)
         window.location.reload();

      // if (typeof window.ga === 'function'){
      //    window.ga('set', 'page', '/novosite2019' + path);
      //    window.ga('set', 'title', title);
      //    window.ga('send', 'pageview');

      //    console.log("era para trocar o pageview", "pageview");
      // }     

      // window.ga('set', 'page', '/novosite2019' + path);
      // window.ga('set', 'title', title);
      // window.ga('send', 'pageview');
   }

   refleshPage() {
      window.location.reload();
   }

   render() {
      return (
         <BrowserRouter basename="/">
            <Suspense fallback={<div className="oc-loading-full"><div className="oc-loader"></div></div>}>
               <Switch>
                  <Route exact path="/" render={() => { this.onUpdateRoute('/', ''); return (<Home />) }} />
                  <Route path="/sobre-o-hospital" render={() => { this.onUpdateRoute('/sobre-o-hospital', 'Conheça nosso Hospital de Olhos'); return (<SobreHospital />) }} />
                  <Route path="/contato" render={() => { this.onUpdateRoute('/contato', 'Contato'); return (<Contato />) }} />
                  <Route path="/convenios" render={() => { this.onUpdateRoute('/convenios', 'Convênios'); return (<Convenios />) }} />

                  {/* ESPECIALIDADES */}
                  <Route exact path="/especialidades" render={() => { this.onUpdateRoute('/especialidades', 'Especialidades'); return (<Especialidades />) }} />
                  <Route path="/especialidades/correcao-da-visao-a-laser" render={() => { this.onUpdateRoute('/especialidades/correcao-da-visao-a-laser', 'Correção da Visão a Laser'); return (<CorrecaoLaser />) }} />
                  <Route path="/especialidades/moderna-cirurgia-de-catarata" render={() => { this.onUpdateRoute('/especialidades/moderna-cirurgia-de-catarata', 'Moderna Cirurgia de Catarata'); return (<CirurgiaCatarata />) }} />
                  <Route path="/especialidades/tratamento-para-ceratoconee-doencas-de-cornea" render={() => { this.onUpdateRoute('/especialidades/tratamento-para-ceratoconee-doencas-de-cornea', 'Tratamento para Ceratocone e doenças de Córnea'); return (<TratamentoCeratocone />) }} />
                  <Route path="/especialidades/retina-e-vitreo" render={() => { this.onUpdateRoute('/especialidades/retina-e-vitreo', 'Retina e Vítreo'); return (<RetinaVitreo />) }} />
                  <Route path="/especialidades/glaucoma" render={() => { this.onUpdateRoute('/especialidades/glaucoma', 'Glaucoma'); return (<Glaucoma />) }} />
                  <Route path="/especialidades/plastica-palpebral" render={() => { this.onUpdateRoute('/especialidades/plastica-palpebral', 'Plástica Palpebral'); return (<PlasticaPelpebral />) }} />
               </Switch>
            </Suspense>
         </BrowserRouter>
      );
   }
}

export default Routes;
