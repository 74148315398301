import React from 'react';

// Rotas
import Routes from './routes';

// CSS
import './assets/css/principal.css';
import './assets/fonts/fontawesome/css/all.min.css';

function App() {  

  return (
    <Routes />
  );
}

export default App;